import {
  CartItemAddInput,
  CartItemRemoveInput,
  Club,
  ClubType,
} from '@fable/types'
import { SearchFilterKey } from 'state'
import { captureException, getReferralId, keysToSnakeCase } from 'utils'
import { main as analytics } from './main'
import {
  AnalytEventAppDownloadProps,
  AnalytEventClub,
  AnalytEventProps,
  AnalytEventPropsBase,
  SplitTestName,
} from './types'
import { ChatbotError } from 'app/social/pages/chat_bot_page/lib/types'
import { singularSdk } from 'singular-sdk'

const defaultEventProps: AnalytEventPropsBase = {
  platform: 'web',
}

const trackEvent = <TProps>(
  title: string,
  properties: Record<string, unknown> | TProps = {}
) => {
  if (window.location.pathname === 'iosprivacy ') return

  const props = keysToSnakeCase(properties)

  analytics.track(title, props)
  singularSdk.event(title, props)
}

const appDownload = ({
  clubType,
  ...props
}: AnalytEventAppDownloadProps = {}) => {
  const properties: AnalytEventProps<AnalytEventClub> = {
    ...props,
    ...defaultEventProps,
  }

  if (!!clubType) properties.clubType = clubType

  trackEvent<AnalytEventProps<AnalytEventAppDownloadProps>>(
    'app_download_cta',
    properties
  )
}

export const smclCardVerification = ({
  successful,
  userId,
}: {
  successful: boolean
  userId: string
}) =>
  trackEvent(successful ? 'smcl_card_verified' : 'smcl_card_failed', {
    userId,
  })

export const referralsTappedCopyShare = () => {
  const properties = {
    type: 'user_invite',
  }

  trackEvent('referrals_tapped_copy_share', properties)
}

export const clubJoined = ({
  club,
  clubType,
}: {
  club: Club | null
  clubType: ClubType | null
}) => {
  const properties = {
    club_type: clubType,
    is_enterprise: !!club?.company,
    is_open: club?.access_type === 'open',
    club_id: club?.id,
    ...defaultEventProps,
  }

  trackEvent('club_joined', properties)
}

export const clubDetailPageViewed = ({
  mediaType,
}: {
  mediaType: 'book' | 'tv_show'
}) => {
  const properties = {
    media_type: mediaType,
    ...defaultEventProps,
  }

  trackEvent('web_club_detail_page_viewed', properties)
}

export const trialStarted = () => {
  trackEvent('trial_started', defaultEventProps)
}

export const splitTestTriggered = ({
  cohort = 'control',
  name,
}: {
  cohort?: 'test' | 'control'
  name: SplitTestName
}) => {
  const properties = {
    test_name: name,
    test_cohort: cohort,
    ...defaultEventProps,
  }

  trackEvent('Split Test Triggered', properties)
}

export const genreFilterEnabled = ({ genre }: { genre: string }) => {
  const properties = {
    web_genre_filter: genre,
  }

  trackEvent('web_genre_filter_enabled', properties)
}

export const searchQuery = ({
  type,
  query,
}: {
  type: SearchFilterKey
  query: string
}) => {
  if (!query.length) return

  const properties = {
    search_query: query,
  }

  trackEvent(`web_${type}_search`, properties)
}

export const popularClubStartingSoon = ({
  club,
  clubType,
}: {
  club: Club | null
  clubType: ClubType | null
}) => {
  const properties = {
    club_type: clubType,
    is_enterprise: !!club?.company,
    is_open: club?.access_type === 'open',
    club_id: club?.id,
    ...defaultEventProps,
  }

  trackEvent('web_clicked_club_carousel_starting_7_days', properties)
}

const seen = () => {
  trackEvent('web_ebook_promo_upsell_seen', defaultEventProps)
}

const buyCta = () => {
  trackEvent('web_ebook_promo_upsell_buy_cta', defaultEventProps)
}

const skipCta = () => {
  trackEvent('web_ebook_promo_upsell_skip_cta', defaultEventProps)
}

const checkoutStart = () => {
  trackEvent('web_ebook_promo_upsell_checkout_start', defaultEventProps)
}

const checkoutComplete = () => {
  trackEvent('web_ebook_promo_upsell_checkout_complete', defaultEventProps)
}
const clubQuizStarted = () => trackEvent('web_club_reco_quiz_started')

const clubQuizGenresSelected = () =>
  trackEvent('web_club_reco_quiz_genres_selected')

const clubQuizFirstSwipe = () =>
  trackEvent('web_club_reco_quiz_first_book_added')

const clubQuizMinBooksAdded = () =>
  trackEvent('web_club_reco_quiz_min_books_added')

const clubQuizBooksExhausted = () =>
  trackEvent('web_club_reco_quiz_books_exhausted')

const clubQuizResultsFailed = () => trackEvent('web_club_reco_quiz_timed_out')

const clubQuizResultsLoaded = () => trackEvent('web_club_reco_quiz_results')

const clubQuizResultClicked = () =>
  trackEvent('web_club_reco_quiz_results_click')

const itemAdded = (params: CartItemAddInput) => {
  const properties = {
    ...params,
    ...defaultEventProps,
  }

  trackEvent('item_added_to_cart', properties)
}

const created = (params: CartItemAddInput) => {
  const properties = {
    ...params,
    ...defaultEventProps,
  }

  trackEvent('cart_created', properties)
}

const itemRemoved = (params: CartItemRemoveInput) => {
  const properties = {
    ...params,
    ...defaultEventProps,
  }

  trackEvent('item_removed_from_cart', properties)
}

const shareObjectCtaClicked = (params: AnalytEventAppDownloadProps) => {
  const properties = {
    ...params,
    ...defaultEventProps,
  }

  trackEvent('share_object_cta_clicked', properties)
}

const dragonQuizEvent = ({ name }: { name: string }) => {
  const { id } = getReferralId()

  const properties: any = {
    ...defaultEventProps,
  }

  if (!!id?.length) {
    properties.referral_id = id
  }

  trackEvent(name, properties)
}

const dragonQuizStarted = () => dragonQuizEvent({ name: 'dragon_quiz_started' })
const dragonQuizFinished = () =>
  dragonQuizEvent({ name: 'dragon_quiz_finished' })
const dragonQuizRegenerate = () =>
  dragonQuizEvent({ name: 'dragon_quiz_regenerate' })
const dragonQuizRestart = () => dragonQuizEvent({ name: 'dragon_quiz_restart' })

const statsViewed = (params: {
  view_type: 'self' | 'other'
  goal_set: boolean
  books_read: number
  starting_from: 'onboarding' | 'stats_page'
}) => {
  const properties: any = {
    ...params,
    ...defaultEventProps,
  }

  trackEvent('stats_viewed', properties)
}

const statsScrolled = () => {
  trackEvent('stats_scrolled', defaultEventProps)
}

const statsGoalSetupModal = () => {
  return trackEvent('stats_goal_set_up_modal', defaultEventProps)
}

const statsThemeChanged = ({ theme_color }: { theme_color: string }) => {
  return trackEvent('stats_theme_changed', {
    theme_color,
    ...defaultEventProps,
  })
}

const statsShareTap = ({ type }: { type: string }) => {
  return trackEvent(`stats_${type}_share_tap`, defaultEventProps)
}

const chatbotError = ({
  ...props
}: {
  uuid: string
  session_id: string
  rec_id: string
  staging: boolean
} & ChatbotError) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }
  return trackEvent('scout_error_returned', properties)
}

const chatbotViewed = ({
  ...props
}: {
  user_id: string
  session_id: string
}) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('scout_viewed', properties)
}

const chatbotFeedbackReceived = ({
  ...props
}: {
  feedback: string
  uuid: string
  session_id: string
}) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('scout_feedback_received', properties)
}

const chatbotFeedbackRequested = ({
  ...props
}: {
  uuid: string
  session_id: string
}) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('scout_feedback_requested', properties)
}

const chatbotBookTap = ({ ...props }: { book_id: string }) => {
  const properties = {
    ...defaultEventProps,
    ...props,
  }
  return trackEvent('scout_bdp_tap', properties)
}

const chatbotQuerySubmitted = ({
  ...props
}: {
  starting_from: 'personalized_prompt' | 'generic_prompt' | 'free_form'
}) => {
  const properties = {
    ...defaultEventProps,
    ...props,
  }

  return trackEvent('scout_query_submitted', properties)
}

const chatbotListAddMediaCompleted = () => {
  const properties = {
    ...defaultEventProps,
    starting_from: 'scout',
    media_type: 'book',
    list_type: 'want_to_read',
  }

  return trackEvent('list_add_media_completed', properties)
}

const webViewAuthViewed = ({
  ...props
}: {
  feature: string
  detail: string
}) => {
  const properties = {
    ...defaultEventProps,
    ...props,
  }
  captureException(new Error('web_view_auth_viewed'), { extra: properties })
  return trackEvent('web_view_auth_viewed', properties)
}

const scoutPickViewed = () => trackEvent('scout_wtr_viewed', defaultEventProps)
const scoutPickViewedFirst = () =>
  trackEvent('scout_wtr_viewed_first', defaultEventProps)
const scoutPickViewedSecond = () =>
  trackEvent('scout_wtr_viewed_second', defaultEventProps)
const scoutPickViewedThird = () =>
  trackEvent('scout_wtr_viewed_third', defaultEventProps)

const scoutPickListAddMedia = ({
  bookId,
  userId,
}: {
  bookId: string
  userId: string
}) => {
  const properties = {
    ...defaultEventProps,
    starting_from: 'scout_wtr',
    media_id: bookId,
    user_id: userId,
  }

  return trackEvent('list_add_media_completed', properties)
}

const webExperimentBucketing = ({
  isTreatment = false,
}: {
  isTreatment: boolean
}) => {
  const properties = {
    ...defaultEventProps,
    experiment_name: 'referralInviteV2',
    state: isTreatment ? 'treatment' : 'control',
  }

  return trackEvent('web_experiment_bucketing', properties)
}

export const events = {
  appDownload,
  clubJoined,
  clubDetailPageViewed,
  genreFilterEnabled,
  popularClubStartingSoon,
  referralsTappedCopyShare,
  searchQuery,
  smclCardVerification,
  splitTestTriggered,
  trialStarted,
  ebookPromoUpsell: {
    seen,
    buyCta,
    skipCta,
    checkoutStart,
    checkoutComplete,
  },
  clubQuizStarted,
  clubQuizGenresSelected,
  clubQuizFirstSwipe,
  clubQuizMinBooksAdded,
  clubQuizBooksExhausted,
  clubQuizResultsFailed,
  clubQuizResultsLoaded,
  clubQuizResultClicked,
  cart: {
    created,
    itemAdded,
    itemRemoved,
  },
  dragonQuiz: {
    started: dragonQuizStarted,
    finished: dragonQuizFinished,
    regenerate: dragonQuizRegenerate,
    restart: dragonQuizRestart,
  },
  stats: {
    statsViewed,
    statsScrolled,
    statsGoalSetupModal,
    statsThemeChanged,
    statsShareTap,
  },
  chatbot: {
    chatbotError,
    chatbotViewed,
    chatbotFeedbackReceived,
    chatbotFeedbackRequested,
    chatbotBookTap,
    chatbotQuerySubmitted,
    chatbotListAddMediaCompleted,
  },
  scoutPick: {
    scoutPickViewed,
    scoutPickViewedFirst,
    scoutPickViewedSecond,
    scoutPickViewedThird,
    scoutPickListAddMedia,
  },
  webView: {
    webViewAuthViewed,
  },
  shareObjectCtaClicked,
  webExperimentBucketing,
}
